exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-detail-js": () => import("./../../../src/pages/blog/detail.js" /* webpackChunkName: "component---src-pages-blog-detail-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-ofca-approved-list-js": () => import("./../../../src/pages/blog/ofca-approved-list.js" /* webpackChunkName: "component---src-pages-blog-ofca-approved-list-js" */),
  "component---src-pages-en-pages-bands-js": () => import("./../../../src/pages/en/pages/bands.js" /* webpackChunkName: "component---src-pages-en-pages-bands-js" */),
  "component---src-pages-en-pages-frequencies-js": () => import("./../../../src/pages/en/pages/frequencies.js" /* webpackChunkName: "component---src-pages-en-pages-frequencies-js" */),
  "component---src-pages-en-pages-qsl-js": () => import("./../../../src/pages/en/pages/qsl.js" /* webpackChunkName: "component---src-pages-en-pages-qsl-js" */),
  "component---src-pages-en-pages-relays-js": () => import("./../../../src/pages/en/pages/relays.js" /* webpackChunkName: "component---src-pages-en-pages-relays-js" */),
  "component---src-pages-en-pages-rst-js": () => import("./../../../src/pages/en/pages/rst.js" /* webpackChunkName: "component---src-pages-en-pages-rst-js" */),
  "component---src-pages-en-pages-starters-js": () => import("./../../../src/pages/en/pages/starters.js" /* webpackChunkName: "component---src-pages-en-pages-starters-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sstv-js": () => import("./../../../src/pages/sstv.js" /* webpackChunkName: "component---src-pages-sstv-js" */)
}

